import Developer from "../types/developer";

const developer: Developer = {
  id: 1,
  firstName: "Chase",
  lastName: "Gaddis",
  email: "cgaddis36@gmail.com",
  github: "https://github.com/cgaddis36",
  title: "Software Developer",
  background: "My main goal as a developer is to build highly personable applications designed to optimize efficiency and create solutions to real-world problems. Innovation & collaboration are what drive me in every project I build. Whether it be developing new services for startups, bringing my own ideas to life, or collaborating in larger team environments, developing solutions that solve business problems and provide widespread accessibility to arbitrary data is what truly greases my gears as a developer. When I am not coding, you can find me throwing the ball with my dog in one of the local parks, or fishing for inshore saltwater gamefish from a kayak. I would be hard-pressed to find a better day than hiking to a mountain stream and fly fishing all day."
}

export default developer